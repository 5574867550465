export const SUPPORTED_WALLETS = {
  metamask: 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  rainbow: '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369',
  phantom: 'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
  coinbase: 'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
  oneInch: 'c286eebc742a537cd1d6818363e9dc53b21759a1e8e5d9b263d0c03ec7703576',
  ledgerLive:
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
  taho: 'cf14642fb8736a99b733ada71863241c823743b16e2a822b3dba24e2fa25014d',
  zerion: 'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18',
  mew: 'f5b4eeb6015d66be3f5940a895cbaa49ef3439e518cd771270e6b553b48f31d2',
  sequence: '1f711d32b1df0f84741fafa2ad1d81599b01297cc7d22d153272cb3ef4232f19',
  blockwallet:
    '1aa28414c95f5024133faf5766d376bb9c853c280d158cd3e22dc2b7b0a95a2d',
  enkrypt: 'fb6ed96272ec885008e896c6146002048d8dc88c0b7e0e6fa42bcadf052a1569',
  obvious: '031f0187049b7f96c6f039d1c9c8138ff7a17fd75d38b34350c7182232cc29aa',
  trust: '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  venly: 'a21d06c656c8b1de253686e06fc2f1b3d4aa39c46df2bfda8a6cc524ef32c20c',
  spot: '74f8092562bd79675e276d8b2062a83601a4106d30202f2d509195e30e19673d',
  dttd: 'c4a289db34ed1b8e29d7e87a2e97c236bb82d72d60c8d73e27e02769facabd4a',
  xdefi: 'f896cbca30cd6dc414712d3d6fcc2f8f7d35d5bd30e3b1fc5d60cf6c8926f98f',
  marble: '959c4774921adfcd49b30c88eb53f3831df6cc8c2f65577fbdd65c26a342577e',
  impersonator:
    'b0cc0007eefed734c0f0c58d3a1bdf52969fb978d32da96928aa3209c18e3c54',
  pillar: 'f3379d4ac112b75ce80222d6e0d18f36667c87086fa65b6d10f118815d224940',
  bitcoin: '107bb20463699c4e614d3a2fb7b961e66f48774cb8f6d6c1aee789853280972c',
  wallet3: '34c19e0afafeb86ffa75df1c04445b8840450217e79d30abc6def9aa537fb7d6',
  cypher: '44ca80bba6838e116e8d0a2c1a1f37041ea322379cc65a71479b6a240b6fcab2',
  bitski: '3b9f67c2c0887f71e4f9ba1bd2bf5b4eb6cda94419abd3f0c5c12931a60928b0',
  unstoppable:
    'b3adea6c0b9172c4a49da31d264a7c4aacd70ea04f6af8e8977ecb974298b13c',
  infinity: '802a2041afdaf4c7e41a2903e98df333c8835897532699ad370f829390c6900f',
  haha: '719bd888109f5e8dd23419b20e749900ce4d2fc6858cf588395f19c82fd036b3',
  ancrypto: '7de190d03faf1f15027a834801f045bc66640045b0d5a0daa4686d7fa89fab74',
  ancryptoWallet:
    'c29c9237e92bc18e141e52aba3aa6d04b1afbe9952a0ab2f96dbd8653645c1df',
  kryptogo: '19418ecfd44963883e4d6abca1adeb2036f3b5ffb9bee0ec61f267a9641f878b',
  sender: '765d15cde3a02e088fbc712cabd527ec7d8efc32c5177fd47337760b1a5e7ec7',
  dawn: '647ced4fad747a3a613abfe160fed7deb4e85d8623ac9329e94b24dd0d86bf00',
  ultimate: '09d3f710148d94993ca9f3ed095594d7cc90ba46137dd803a8904b6dbb4bd89c',
  altme: '94135dbd7aaca4908de49c44e49b8920a79c90164a7ce5803ddb33054c7edc57',
  kresus: '56bec983b47c8b6eb774890c1c8ae9d95334e10bdb126ab6c11dfaf56fb2b31c',
  holdstation:
    'b83a346877b71c02b8531f53485ce12bc00033eabcc1213ca3329cbc744813a5',
  alpha: '138f51c8d00ac7b9ac9d8dc75344d096a7dfe370a568aa167eabc0a21830ed98',
  ambire: '2c81da3add65899baeac53758a07e652eea46dbb5195b8074772c62a77bbf568',
  coin98: '2a3c89040ac3b723a1972a33a125b1db11e258a6975d3a61252cd64e6ea5ea01',
  dcent: 'a1f506a38f39b672b369bd13b68abbbd81f83a0489e6625f2bf12aa0389c22ae',
  exodus: 'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
  loopring: '3968c3f5e1aa69375e71bfc3da08a1d24791ac0b3d1c3b1c7e3a2676d175c856',
  math: '7674bb4e353bf52886768a3ddc2a4562ce2f4191c80831291218ebd90f5f5e26',
  omni: 'afbd95522f4041c71dd4f1a065f971fd32372865b416f95a0b1db759ae33f2a7',
  safepal: '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
  torus: '3fecad5e2f0a30aba97edea69ebf015884a9b8a9aec93e66d4b4b695fee1f010',
  wirex: 'b2ce31fb31735fa886270806340de999f72342a7c29484badd8d4d013d77c8b8',
  binance: '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
  binance_us:
    'cbc11415130d01316513f735eac34fd1ad7a5d40a993bbb6772d2c02eeef3df8',
};

export const SUPPORTED_WALLETS_IDS = Object.values(SUPPORTED_WALLETS);
