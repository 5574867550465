/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import cn from 'classnames';

import s from './Tooltip.module.scss';

export interface TooltipProp extends TippyProps {
  children: React.ReactElement<any>;
  tooltip: ReactElement | string;
  className?: string;
  triggerClassName?: string;
}

export const Tooltip: React.FC<TooltipProp> = ({
  children,
  tooltip,
  className,
  triggerClassName,
  ...rest
}) => {
  return (
    <Tippy
      content={tooltip}
      arrow={true}
      animation="perspective"
      delay={100}
      placement="bottom"
      className={cn(s.root, className)}
      {...rest}
    >
      <div className={cn(s.trigger, triggerClassName)}>{children}</div>
    </Tippy>
  );
};
