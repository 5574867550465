import s from './Loader.module.scss';

export const Loader = () => {
  return (
    <div className={s.root}>
      Loading...
      <div className={s.line} />
    </div>
  );
};
