/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloLink, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import {
  getAccessToken,
  getAccessTokenFromStorage,
  getGoogleIdsFromStorage,
  handleRefreshResponse,
  handleSaveToken,
  isTokenExpired,
  returnApiKeyHeader,
} from './utils/credentials';
import * as Sentry from '@sentry/browser';
import { appConfig } from '@app/config';

export const httpLink = createHttpLink({
  uri: appConfig.api.investorApiUrl,
});

export const authLink = setContext((operation, { headers }) => {
  const token = getAccessTokenFromStorage();
  const apiKey = returnApiKeyHeader(operation);
  const googleIds = getGoogleIdsFromStorage();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      ...apiKey,
      'x-ga-session-id': googleIds.session_id,
      'x-ga-client-id': googleIds.client_id,
    },
  };
});

export const refreshLink = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'refreshTokens',
    isTokenValidOrUndefined: async () => isTokenExpired(),
    fetchAccessToken: async () => await getAccessToken(),
    handleFetch: (tokens) => handleSaveToken(tokens),
    handleResponse: (operation, accessTokenField) => handleRefreshResponse,
    handleError: (error) => {
      console.error('Cannot refresh access token:', error);

      if (navigator.onLine) {
        localStorage.clear();
        window.location.reload();
      }
    },
  }),
]);

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.error(graphQLErrors);

      for (const err of graphQLErrors) {
        const customError = err as any;

        if (customError?.errorType === 'UnauthorizedException') {
          localStorage.clear();
          window.location.reload();
        }

        Sentry.withScope((scope) => {
          scope.setLevel('error');
          scope.setTag('kind', operation.operationName);
          scope.setExtra('query', operation.query);

          const sanitizedVariables = { ...operation.variables };

          delete sanitizedVariables?.refreshToken;
          delete sanitizedVariables?.accessToken;

          scope.setExtra('variables', sanitizedVariables);

          Sentry.captureException(err);
        });
      }
    }

    if (networkError) console.error(`[Network error]: ${networkError}`);
  },
);
