import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useUserContext } from '@app/context/UserContextProvider';
import {
  ChooseBoxGameStatsDocument,
  ChooseBoxOpenBoxDocument,
  ChooseBoxUserStatsDocument,
} from '@app/__generated__/graphql';
import { AudioPlayer } from '@app/utils';
import { useScrollLock } from '.';
import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import {
  ChooseBoxAudio,
  ChooseBoxGameState,
  ChooseBoxPrizeType,
} from '@app/components/ChooseTheBox/types';

export const REQUIRED_LEVEL_TO_PLAY = 2;
const BOXES = Array(3).fill(null);

export const useChooseBox = () => {
  const { user } = useUserContext();
  const isDiscordAuthorized = Boolean(user?.discordId);
  const audio = useRef(new AudioPlayer(ChooseBoxAudio));

  const [
    getUserStats,
    {
      data: userStatsData,
      refetch: refetchUserStats,
      loading: userStatsLoading,
    },
  ] = useLazyQuery(ChooseBoxUserStatsDocument);
  const [getGameStats, { data: gameStatsData, refetch: refetchGameStats }] =
    useLazyQuery(ChooseBoxGameStatsDocument);

  const [openBox, { loading: openBoxLoading }] = useMutation(
    ChooseBoxOpenBoxDocument,
  );
  const [isSoundActive, setIsSoundActive] = useState(true);
  const [soundtrackStarted, setSoundtrackStarted] = useState(false);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [gameState, setGameState] = useState(ChooseBoxGameState.init);
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const [openClicked, setOpenClicked] = useState(false);
  const [giftAwarded, setGiftAwarded] = useState<false | ChooseBoxPrizeType>(
    false,
  );

  const userStats = userStatsData?.christmasGame?.getUserStats;
  const gameStats = gameStatsData?.christmasGame?.getGameStats;
  const discordLevel = user?.discordLevel;
  const todayTriesLeft = userStats?.availableTriesToday;
  const totalTriesLeft = userStats?.availableTries;
  const rewardsLeft = gameStats?.rewardsLeft;

  const welcomeScreenVisible =
    gameState === ChooseBoxGameState.welcome &&
    isDiscordAuthorized &&
    !openBoxLoading;

  const loginWindowVisible = !isDiscordAuthorized;

  const shouldShowBoxes =
    gameState !== ChooseBoxGameState.dailyLimit &&
    userStats?.availableTries &&
    userStats.availableTries > 0 &&
    gameState !== ChooseBoxGameState.gameEndedByDate &&
    gameState !== ChooseBoxGameState.gameEndedByRewards;

  const isBoxesInteractive =
    gameState == ChooseBoxGameState.started &&
    selectedBox === null &&
    !userStatsLoading;

  useScrollLock(
    welcomeScreenVisible || loginWindowVisible || selectedBox !== null,
  );

  useEffect(() => {
    const audioInst = audio.current;

    return () => {
      audioInst.stopAll();
    };
  }, []);

  useEffect(() => {
    if (isDiscordAuthorized) {
      getUserStats();
      getGameStats();
    }
  }, [
    getGameStats,
    getUserStats,
    isDiscordAuthorized,
    userStats?.availableTriesToday,
  ]);

  useEffect(() => {
    const handleGameState = () => {
      if (
        todayTriesLeft === null ||
        todayTriesLeft === undefined ||
        discordLevel === null ||
        discordLevel === undefined ||
        totalTriesLeft === undefined ||
        totalTriesLeft === null
      ) {
        return;
      }

      const currentDate = new Date();
      const gameEndDate = new Date(gameStats?.endDate);
      const isGameOverByDate = currentDate >= gameEndDate;
      const isZeroRewardsLeft = rewardsLeft === 0;

      const isLowLevel = discordLevel < REQUIRED_LEVEL_TO_PLAY;
      const isAllTriesUsed =
        discordLevel >= REQUIRED_LEVEL_TO_PLAY && totalTriesLeft === 0;
      const isShowStartScreen = todayTriesLeft > 0 && gamesPlayed === 0;
      const isAutoStartGame = todayTriesLeft > 0 && gamesPlayed > 0;
      const isDailyLimit =
        todayTriesLeft === 0 &&
        discordLevel >= REQUIRED_LEVEL_TO_PLAY &&
        totalTriesLeft > 0;
      const isShowPopup = isLowLevel || isAllTriesUsed || isShowStartScreen;

      if (isGameOverByDate) {
        return setGameState(ChooseBoxGameState.gameEndedByDate);
      } else if (isZeroRewardsLeft) {
        return setGameState(ChooseBoxGameState.gameEndedByRewards);
      } else if (isShowPopup) {
        setGameState(ChooseBoxGameState.welcome);
      } else if (isAutoStartGame) {
        return setGameState(ChooseBoxGameState.started);
      } else if (isDailyLimit) {
        return setGameState(ChooseBoxGameState.dailyLimit);
      }
    };

    handleGameState();
  }, [
    discordLevel,
    gameStats?.endDate,
    gamesPlayed,
    rewardsLeft,
    todayTriesLeft,
    totalTriesLeft,
  ]);

  const startSoundtrack = useCallback(() => {
    if (!soundtrackStarted) {
      audio.current.play('music', { volume: 0.05, loop: true });

      setSoundtrackStarted(true);
    }
  }, [soundtrackStarted]);

  const handleStartGame = useCallback(() => {
    if (gameState === ChooseBoxGameState.dailyLimit) {
      return;
    }

    audio.current.play('startGameClick');
    startSoundtrack();

    setGameState(ChooseBoxGameState.started);
  }, [gameState, startSoundtrack]);

  const handleResetGame = async () => {
    await refetchUserStats();
    await refetchGameStats();
    setGiftAwarded(false);
    setSelectedBox(null);
    setOpenClicked(false);
  };

  const handleBoxClick = async (index: number) => {
    audio.current.play('buttonClick');

    window.scrollTo(0, 0);
    setSelectedBox(index);
    setGamesPlayed(gamesPlayed + 1);

    try {
      const result = await openBox({
        variables: {
          boxNum: index,
        },
      });

      const prize = result.data?.christmasGame?.openBox.reward;

      if (prize === null) {
        setGiftAwarded(ChooseBoxPrizeType.failed);
      }

      let prizeType;

      switch (prize) {
        case 'worm':
          prizeType = ChooseBoxPrizeType.worm;
          break;
        case 'card':
          prizeType = ChooseBoxPrizeType.card;
          break;
        case 'accessory':
          prizeType = ChooseBoxPrizeType.accessory;
          break;
        case 'tokens':
          prizeType = ChooseBoxPrizeType.wofr;
          break;
      }

      if (prizeType) {
        setGiftAwarded(prizeType);
      }
    } catch (e) {
      console.error(e);
      handleResetGame();
      toast(
        <ToastNotification
          type={NotificationType.error}
          message={'Error: something went wrong'}
        />,
        {
          autoClose: false,
        },
      );
    }
  };

  const handleOpenBox = () => {
    audio.current.play('buttonClick');
    setOpenClicked(true);

    setGameState(ChooseBoxGameState.over);
  };

  const handleSound = () => {
    setIsSoundActive(!isSoundActive);
    audio.current.muteUnmute();
  };

  return {
    boxes: BOXES,
    shouldShowBoxes,
    requiredLevelToPlay: REQUIRED_LEVEL_TO_PLAY,
    user,
    gameState,
    userStats,
    gameStats,
    selectedBox,
    userStatsLoading,
    openBoxLoading,
    giftAwarded,
    openClicked,
    welcomeScreenVisible,
    loginWindowVisible,
    isSoundActive,
    isBoxesInteractive,
    handleSound,
    startSoundtrack,
    handleStartGame,
    handleBoxClick,
    handleOpenBox,
    handleResetGame,
  };
};
