import { useEffect } from 'react';

export const useScrollLock = (shouldLock: boolean) => {
  useEffect(() => {
    const enableScroll = () => {
      document.body.style.overflow = 'unset';
      document.body.removeEventListener('touchmove', preventDefault);
    };

    const disableScroll = () => {
      document.body.style.overflow = 'hidden';

      document.body.addEventListener('touchmove', preventDefault, {
        passive: false,
      });
    };

    const preventDefault = (e: TouchEvent) => {
      const portalEl = document.getElementById(
        'REACT_PORTAL',
      ) as HTMLDivElement;

      if (shouldLock && portalEl && !portalEl.contains(e.target as Node)) {
        e.preventDefault();
      }
    };

    if (shouldLock) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll();
    };
  }, [shouldLock]);
};
