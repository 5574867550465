'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { AnimatePresence, m } from 'framer-motion';

import { FRAMER_OPACITY_TRANSITION } from '@app/constants/framerTransitions';
import { Icon } from '@app/ui-kit';
import { useScrollLock } from '@app/hooks';

import s from './Modal.module.scss';

export const modalSlideIn = {
  initial: { opacity: 0, y: 700 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      delay: 0.5,
      duration: 0.5,
    },
  },
  exit: { opacity: 0, y: 700 },
  transition: {
    type: 'tween',
    duration: 0.5,
  },
};

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
  withCloseIcon?: boolean;
  withStyledContainer?: boolean;
  transparentOverlay?: boolean;
  containerClassName?: string;
  onClose?: (result: false) => void;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  children,
  withCloseIcon,
  withStyledContainer = true,
  transparentOverlay,
  containerClassName,
  onClose,
}: ModalProps) => {
  const [portal, setPortal] = useState<null | HTMLDivElement>();

  useScrollLock(isOpen);

  useEffect(() => {
    const portalEl = document.getElementById('REACT_PORTAL') as HTMLDivElement;

    setPortal(portalEl);
  }, []);

  if (!portal) {
    return null;
  }

  const modal = (
    <AnimatePresence>
      {isOpen && (
        <m.div
          className={cn(s.root, { [s.transparentOverlay]: transparentOverlay })}
          {...FRAMER_OPACITY_TRANSITION}
        >
          <m.div
            {...modalSlideIn}
            className={cn(
              s.container,
              {
                [s.withClose]: withCloseIcon,
                [s.styled]: withStyledContainer,
              },
              containerClassName,
            )}
          >
            {withCloseIcon && (
              <Icon
                name="close"
                onClick={() => onClose?.(false)}
                className={s.close}
              />
            )}

            {children}
          </m.div>
        </m.div>
      )}
    </AnimatePresence>
  );

  return ReactDOM.createPortal(modal, portal);
};

export default Modal;
