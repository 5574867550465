/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { FormEvent, useEffect, useState } from 'react';
import { formatEther, parseEther, parseUnits } from 'viem';
import {
  Address,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi';

import {
  SaleGetBuyParamsDocument,
  SaleGetPurchaseHistoryDocument,
} from '@app/__generated__/graphql';
import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import { useUserContext } from '@app/context/UserContextProvider';
import { contracts } from '@app/contracts';
import {
  ToFixedRounding,
  etherScanTxLink,
  getUserDiscounts,
  sendAnalyticsEvent,
  toFixed,
} from '@app/utils';
import { ExternalLink, ExternalLinkVariant } from '@app/ui-kit';
import investorClient from '@app/services/investor/investorClient';
import { usePollInterval } from '.';
import { getGasPrice } from '@app/utils/getGasPrice';
import { ProgressStep } from '@app/components/ProgressIndicator';
import { appConfig } from '@app/config';
import { useAuthContext } from '@app/context/AuthContextProvider';
import { polygon } from 'viem/chains';

const INPUT_PRECISION = 3;
const APPROVE_GAS_LIMIT = BigInt('60000');
const BUY_GAS_LIMIT = BigInt('250000');
const CLASSIC_BUY_STEPS = [
  'Approving spending',
  'Confirming purchase',
  'Completing purchase',
];

export const useClassicBuyWOFR = () => {
  const [amountWOFR, setAmountWOFR] = useState<string | null>(null);
  const [amountUSDT, setAmountUSDT] = useState<string | null>(null);
  const [amountUsdtWei, setAmountUsdtWei] = useState<bigint | null>(null);
  const [termsAgreed, setTermsAgreed] = useState(true);
  const [currentStep, setCurrentStep] = useState<ProgressStep>({
    stepIndex: -1,
    lineIndex: -1,
  });
  const { user, balanceUSDT, balanceWOFR } = useUserContext();
  const balance = balanceUSDT || '0';
  const USDT_18Decimals = amountUsdtWei;
  const { isVerified, openVerifyModal } = useAuthContext();

  const refetchPurchaseTableData = () => {
    investorClient.refetchQueries({
      include: [SaleGetPurchaseHistoryDocument],
    });
  };

  const {
    startPolling: startRefreshPurchaseTableData,
    stopPolling: stopRefreshingPurchaseTableData,
  } = usePollInterval({
    callback: refetchPurchaseTableData,
  });

  const { data: wofrPriceData, isLoading: wofrPriceDataLoading } =
    useContractRead({
      ...contracts.tokenSale,
      functionName: 'tokenPriceUsdt',
      enabled: Boolean(user),
    });

  const formattedWofrPrice = wofrPriceData ? +formatEther(wofrPriceData) : 0;
  const discounts = getUserDiscounts(user?.discounts);

  const wofrPriceWeiDiscounted = wofrPriceData
    ? (BigInt(wofrPriceData) * BigInt((100 - discounts.total) * 10)) /
      BigInt(1000)
    : BigInt(0);

  const wofrPriceWithoutDiscount = formatEther(wofrPriceData || BigInt(0));
  const wofrPriceDiscounted = formatEther(wofrPriceWeiDiscounted);

  const getTokensUsdtPriceInWei = (
    wofrPrice: bigint | undefined,
    tokensAmount: string,
  ) => {
    if (!wofrPrice) {
      return BigInt('0');
    }

    return (wofrPrice * parseEther(tokensAmount)) / parseEther('1');
  };

  const maxWOFR = toFixed(
    Number(balance) / Number(wofrPriceDiscounted),
    INPUT_PRECISION,
  );

  const {
    isLoading: availableUsdtAllowanceLoading,
    refetch: refetchAllowance,
  } = useContractRead({
    ...contracts.tether,
    functionName: 'allowance',
    args: [user?.address as `0x${string}`, contracts.tokenSale.address],
    cacheTime: 0,
  });

  const {
    writeAsync: approveUsdt,
    data: approveUsdtData,
    isLoading: approveUsdtDataLoading,
  } = useContractWrite({
    ...contracts.tether,
    functionName: 'approve',
  });

  const handleBuyTokens = async () => {
    if (!USDT_18Decimals || !user) {
      return;
    }

    setCurrentStep({ stepIndex: 1, lineIndex: 1 });

    try {
      const buyParamsResponse = await investorClient.query({
        query: SaleGetBuyParamsDocument,
        variables: { amountUsdt: USDT_18Decimals.toString() },
      });

      const buyParams = buyParamsResponse.data?.tokenSale?.getBuyParams;

      await buyTokens({
        args: [
          buyParams.to as `0x${string}`,
          BigInt(buyParams.amountUsdt),
          BigInt(buyParams?.discountPercent),
          buyParams.referralWallet as Address,
          BigInt(buyParams.referralRewardPercent),
          buyParams.signature as `0x${string}`,
        ],
        gas: BUY_GAS_LIMIT,
      });
      setCurrentStep({ stepIndex: 1, lineIndex: 2 });
    } catch (e) {
      setCurrentStep({ stepIndex: 1, lineIndex: 1, isError: true });
      console.error(e);
    }
  };

  const { isLoading: approveUsdTxLoading } = useWaitForTransaction({
    hash: approveUsdtData?.hash,
    confirmations: appConfig.env === 'production' ? 2 : 1,

    onSuccess(data) {
      console.info('Success', data);
      toast(
        <ToastNotification icon="usdt">
          <div>
            Request a spending limit for your USDT completed, you can view
            details{' '}
            <ExternalLink
              href={etherScanTxLink(data.transactionHash)}
              variant={ExternalLinkVariant.black}
              label="on a block explorer"
              useParentFontSize
            />
            . Please proceed with next transaction.
          </div>
        </ToastNotification>,
      );
      handleBuyTokens();
    },
    onError(error) {
      console.error('Error', error);
      toast(
        <ToastNotification
          type={NotificationType.error}
          title="Transaction failed:"
          message={error.name}
        />,
        {
          autoClose: false,
        },
      );
    },
  });

  const {
    isLoading: buyWofrLoading,
    writeAsync: buyTokens,
    data: buyTokensTxData,
  } = useContractWrite({
    ...contracts.tokenSale,
    functionName: 'buy',
    onError(error: any) {
      console.error('Error', error);

      toast(
        <ToastNotification
          type={NotificationType.error}
          icon="wofr"
          title="Unable to form transaction"
          message={error?.shortMessage}
        />,
        {
          autoClose: false,
        },
      );
    },
  });

  const { isLoading: buyTokensTxLoading } = useWaitForTransaction({
    hash: buyTokensTxData?.hash,
    onSuccess(data) {
      console.info('Success', data);

      setCurrentStep({ stepIndex: 3, lineIndex: 3 });
      startRefreshPurchaseTableData();
      toast(
        <ToastNotification icon="wofr">
          <div>
            Congratulations, you bought WOFR tokens! You can view details{' '}
            <ExternalLink
              href={etherScanTxLink(data.transactionHash)}
              variant={ExternalLinkVariant.black}
              label="on a block explorer"
              useParentFontSize
            />
            .
          </div>
        </ToastNotification>,
      );

      const txAmounts = { wofr: amountWOFR || 0, usdt: amountUSDT || 0 };

      const gAEventName =
        balanceWOFR != undefined && +balanceWOFR === 0
          ? 'first_token_purchase'
          : 'repeat_token_purchase';

      const referrals_total_usdt = user?.invitedByCode
        ? ((user?.referralCodeDiscountPercent || 0) * +txAmounts.usdt) / 100
        : 0;

      sendAnalyticsEvent({
        name: gAEventName,
        user_id: user?.address,
        variables: {
          purchase_method: 'for_geeks',
          referrals_code: user?.invitedByCode || '',
          referrals_total_usdt: referrals_total_usdt.toFixed(2),
          referral_discount: discounts.referral,
          nft_discount: discounts.nft,
          whitelist_discount: discounts.whitelist,
          purchase_total_wofr: Number(txAmounts.wofr).toFixed(2),
          purchase_total_usdt: Number(txAmounts.usdt).toFixed(2),
        },
      });

      setAmountWOFR(null);
      setAmountUSDT(null);
    },
    onError(error) {
      console.error('Error', error);
      setCurrentStep({ stepIndex: 3, lineIndex: 3, isError: true });

      toast(
        <ToastNotification
          type={NotificationType.error}
          title="Transaction failed:"
          message={error.message}
        />,
        {
          autoClose: false,
        },
      );
    },
  });

  useEffect(() => {
    if (buyTokensTxLoading) {
      setCurrentStep({ stepIndex: 2, lineIndex: 2 });
    }
  }, [buyTokensTxLoading]);

  const isLoading =
    wofrPriceDataLoading ||
    approveUsdtDataLoading ||
    buyWofrLoading ||
    approveUsdTxLoading ||
    buyTokensTxLoading;

  const buyDisabled =
    !termsAgreed ||
    !amountUSDT ||
    !amountWOFR ||
    +amountUSDT <= 0 ||
    +amountWOFR < 1 ||
    formattedWofrPrice === 0 ||
    availableUsdtAllowanceLoading ||
    isLoading;

  const handleChangeWOFR = (val: string) => {
    setAmountWOFR(val);

    const usdtAmountWei = getTokensUsdtPriceInWei(wofrPriceWeiDiscounted, val);
    const usdtAmountEther = formatEther(usdtAmountWei);

    if (Number(usdtAmountEther) > Number(balance)) {
      setAmountUSDT(toFixed(balance, INPUT_PRECISION, ToFixedRounding.Up));
      setAmountUsdtWei(parseEther(balance));
    } else {
      setAmountUSDT(
        toFixed(usdtAmountEther, INPUT_PRECISION, ToFixedRounding.Up),
      );
      setAmountUsdtWei(usdtAmountWei);
    }
  };

  const handleChangeUSDT = (val: string) => {
    setAmountUSDT(val);
    setAmountUsdtWei(parseEther(val));

    const wofrAmount = parseFloat(val) / parseFloat(wofrPriceDiscounted);

    if (wofrAmount > parseFloat(maxWOFR)) {
      setAmountWOFR(maxWOFR);
    } else {
      setAmountWOFR(
        isNaN(wofrAmount) ? '0' : toFixed(wofrAmount, INPUT_PRECISION),
      );
    }
  };

  const buyWOFR = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isVerified) {
      return openVerifyModal();
    }

    const allowance = await refetchAllowance();

    if (!amountUsdtWei || allowance.data == undefined) {
      return;
    }

    const USDT_6Decimals = amountUsdtWei / parseUnits('1', 12);

    const requiredAllowanceAmount =
      BigInt(USDT_6Decimals) - BigInt(allowance.data);

    if (requiredAllowanceAmount <= 0) {
      toast(
        <ToastNotification
          icon="wofr"
          title="WOFR Purchase"
          message="Please check your wallet and approve transaction."
        />,
      );
      handleBuyTokens();

      return;
    }

    setCurrentStep({ stepIndex: 0, lineIndex: 0 });
    stopRefreshingPurchaseTableData();

    const fastGasPrice = await getGasPrice();

    try {
      toast(
        <ToastNotification
          type={NotificationType.info}
          message="Please check your wallet and confirm transaction"
        />,
        { autoClose: 3000 },
      );
      await approveUsdt({
        args: [contracts.tokenSale.address, USDT_6Decimals],
        gas: APPROVE_GAS_LIMIT,
        ...(fastGasPrice && { gasPrice: fastGasPrice }),
      });
      setCurrentStep({ stepIndex: 0, lineIndex: 1 });
    } catch (e: any) {
      console.error(e);
      toast(
        <ToastNotification
          type={NotificationType.error}
          title="Transaction failed"
          message={e.shortMessage}
        />,
        { autoClose: false },
      );
      setCurrentStep({ stepIndex: 0, lineIndex: 0, isError: true });
    }
  };

  return {
    amountWOFR,
    amountUSDT,
    wofrPriceWithoutDiscount,
    wofrPriceDiscounted,
    discounts,
    maxWOFR,
    balance,
    termsAgreed,
    buyDisabled,
    isLoading,
    classicBuySteps: CLASSIC_BUY_STEPS,
    currentStep,
    handleChangeWOFR,
    handleChangeUSDT,
    setTermsAgreed,
    buyWOFR,
  };
};
