'use client';

import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { ButtonSecondary, Icon, IconName, InputErrorText } from '@app/ui-kit';

import s from './Input.module.scss';

export enum InputActionButtonType {
  setMax,
  copy,
}

export type InputActionButton = {
  type: InputActionButtonType;
  label: string;
  copyPrefixText?: string;
};

interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  value: string | null;
  topLabel?: string;
  topChip?: string;
  bottomLabel?: string;
  bottomValue?: string;
  bottomNewValue?: string;
  icon?: IconName;
  numbersOnly?: boolean;
  maxValue?: string | number;
  actionButton?: InputActionButton;
  placeholder?: string;
  errorText?: string | null | undefined;
  forceErrorState?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  editable?: boolean;
  className?: string;
  inputClassName?: string;
  onChange: (val: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEnterPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onActionButtonClick?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      topLabel,
      topChip,
      bottomLabel,
      bottomValue,
      bottomNewValue,
      icon,
      numbersOnly,
      maxValue,
      actionButton,
      placeholder,
      errorText,
      forceErrorState,
      disabled,
      autoComplete,
      editable = true,
      className,
      inputClassName,
      onChange,
      onEnterPress,
      onKeyDown,
      onActionButtonClick,
      ...rest
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const actionButtonLabelRef = useRef<HTMLButtonElement>(null);

    const classNames = cn(s.root, className, {
      [s.hasError]: errorText || forceErrorState,
      [s.disabled]: disabled,
    });

    useEffect(() => {
      if (actionButtonLabelRef.current && inputRef.current) {
        const gap = 20;
        const buttonWidth =
          actionButtonLabelRef.current.getBoundingClientRect().width;

        const paddingValue = `${buttonWidth + gap}px`;

        inputRef.current.style.paddingRight = paddingValue;
      }
    }, []);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e);

      if (e.key === 'Enter') {
        e.preventDefault();
        onEnterPress?.(e);
      }

      if (numbersOnly) {
        const isValidInput = /^[0-9.]$/.test(e.key);

        if (
          !isValidInput &&
          !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(
            e.key,
          )
        ) {
          e.preventDefault();
        }

        if (
          e.key === '.' &&
          (e.currentTarget.value === '' || e.currentTarget.value === '0') &&
          e.currentTarget.selectionStart === 0
        ) {
          e.preventDefault();
        }

        if (
          e.key === '.' &&
          (e.currentTarget.value.match(/\./g) || []).length >= 1
        ) {
          e.preventDefault();
        }
      }
    };

    const handleChange = (val: string) => {
      if (!editable) {
        return;
      }

      if (numbersOnly) {
        const regex = /^\d*\.?\d{0,3}$/;

        if (!regex.test(val)) {
          return;
        }

        if (maxValue !== undefined) {
          const isOverMaxValue = Number(val) > Number(maxValue);

          if (isOverMaxValue) {
            onChange(maxValue.toString());

            return;
          }
        }
      }

      onChange(val);
    };

    const handleSetMax = () => {
      if (maxValue) {
        onChange(maxValue.toString());
      }

      onActionButtonClick?.();
    };

    const handleCopyValue = () => {
      if (navigator.clipboard && value) {
        const text = actionButton?.copyPrefixText
          ? `${actionButton?.copyPrefixText}${value}`
          : value;

        navigator.clipboard.writeText(text);
        onActionButtonClick?.();
      }
    };

    const renderActionButton = () => {
      if (!actionButton) {
        return null;
      }

      switch (actionButton?.type) {
        case InputActionButtonType.setMax:
          return (
            <button
              type="button"
              className={s.actionButton}
              onClick={handleSetMax}
              ref={actionButtonLabelRef}
            >
              <span>{actionButton.label}</span>
            </button>
          );

        case InputActionButtonType.copy:
          return (
            <ButtonSecondary
              ref={actionButtonLabelRef}
              label={actionButton.label}
              onClick={handleCopyValue}
              iconName="copy"
              className={s.copyButton}
            />
          );
      }
    };

    return (
      <div className={classNames} onClick={() => inputRef.current?.focus()}>
        {(topLabel || topChip) && (
          <div className={s.top}>
            {topLabel && <span className={s.topLabel}>{topLabel}</span>}
            {topChip && <span className={s.topChip}>{topChip}</span>}
          </div>
        )}
        <div className={s.inputWrapper}>
          <input
            ref={ref || inputRef}
            className={cn(s.input, inputClassName, {
              [s.withIcon]: icon,
              [s.numeric]: numbersOnly,
              [s.notEditable]: !editable,
            })}
            value={value || ''}
            autoComplete={autoComplete}
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
            onKeyDown={handleKeyPress}
            type="text"
            disabled={disabled}
            {...rest}
          />
          {icon && <Icon name={icon} className={s.icon} />}
          {renderActionButton()}
        </div>

        {(bottomLabel || bottomValue) && (
          <div className={s.bottom}>
            {bottomLabel && (
              <span className={s.bottomLabel}>{bottomLabel}</span>
            )}
            {bottomValue && (
              <span
                className={cn(s.bottomValue, {
                  [s.lineTrough]: bottomValue && bottomNewValue,
                })}
              >
                {bottomValue}
              </span>
            )}
            {bottomValue && bottomNewValue && (
              <span className={s.bottomNewValue}>{bottomNewValue}</span>
            )}
          </div>
        )}
        <InputErrorText errorText={errorText} />
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
