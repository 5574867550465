import { Input } from '@app/ui-kit';

import s from './EmailDialog.module.scss';

interface EmailDialogProps {
  value: string;
  errorText: string | null;
  onChange: (value: string) => void;
  onEnterPress: () => void;
}

const EmailDialog: React.FC<EmailDialogProps> = ({
  value,
  errorText,
  onChange,
  onEnterPress,
}) => {
  return (
    <>
      <div className={s.description}>
        We will send verification code to provided email
      </div>
      <Input
        value={value}
        placeholder="Type in your email..."
        autoComplete="email"
        errorText={errorText}
        onChange={onChange}
        onEnterPress={onEnterPress}
      />
    </>
  );
};

export default EmailDialog;
