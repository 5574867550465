import { RefObject, useEffect, useState } from 'react';

export const useVerticalScrollGradient = (
  ref: RefObject<HTMLDivElement>,
  isEnabled = true,
) => {
  const [gradientVisible, setGradientVisible] = useState({
    top: false,
    bottom: true,
  });

  useEffect(() => {
    const handleGradientVisibility = () => {
      const el = ref.current;

      if (!el) return;

      const scrollY = el.scrollTop;
      const scrollHeight = el.scrollHeight;
      const elHeight = el.clientHeight;

      if (scrollY < 1) {
        return setGradientVisible({ top: false, bottom: true });
      }

      if (scrollHeight - scrollY === elHeight) {
        return setGradientVisible({ top: true, bottom: false });
      }

      return setGradientVisible({ top: true, bottom: true });
    };

    const scrollableBody = ref.current;

    if (isEnabled) {
      scrollableBody?.addEventListener('scroll', handleGradientVisibility);
    }

    return () =>
      scrollableBody?.removeEventListener('scroll', handleGradientVisibility);
  }, [ref, isEnabled]);

  return gradientVisible;
};
