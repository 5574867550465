import React, { createContext, useContext } from 'react';
import { User } from '@app/__generated__/graphql';
import ModalReferralSignUp from '@app/components/ModalReferralSignUp';
import { useAuth, useReferralCode, useVerifyUserAccount } from '@app/hooks';
import ModalUserVerification from '@app/components/ModalUserVerification';

type AuthContextValue = {
  isLoading: boolean;
  isAuth: boolean;
  isVerified: boolean | undefined;
  isTelegramConnected: boolean | undefined;
  user: User | null;
  logout: () => Promise<void>;
  openVerifyModal: () => void;
};

const initialContextValue: AuthContextValue = {
  isLoading: false,
  isAuth: false,
  isVerified: false,
  isTelegramConnected: false,
  user: null,
  logout: () => {
    throw new Error('Method login is not implemented!');
  },
  openVerifyModal: () => {},
};

const AuthContext = createContext<AuthContextValue>(initialContextValue);

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { referralCode, referralModalOpen, closeReferralModal } =
    useReferralCode();
  const { currentUser, authLoading, modalSignMessage, logout } = useAuth();
  const isAuth = !!currentUser;
  const isLoading = authLoading;
  const shouldShowReferralModal = referralModalOpen && !isLoading;

  const { isVerified, verifyModalOpen, openVerifyModal, closeVerifyModal } =
    useVerifyUserAccount({
      currentUser,
      isAuth: isAuth && !isLoading,
    });

  const value = React.useMemo(
    (): AuthContextValue => ({
      isLoading,
      user: currentUser,
      isAuth,
      isVerified,
      isTelegramConnected: Boolean(currentUser?.telegramId),
      logout,
      openVerifyModal,
    }),
    [isLoading, currentUser, isAuth, isVerified, logout, openVerifyModal],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
      {modalSignMessage}
      <ModalReferralSignUp
        referralCode={referralCode}
        isOpen={shouldShowReferralModal}
        onClose={closeReferralModal}
      />
      <ModalUserVerification
        variant="UserVerification"
        isOpen={verifyModalOpen}
        onClick={closeVerifyModal}
      />
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextValue => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }

  return context;
};
