import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi';

import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import {
  getReferralCodeFromStorage,
  saveReferralCodeToStorage,
} from '@app/services/investor/utils/credentials';
import { getQueryParams, sendAnalyticsEvent } from '@app/utils';

export const useReferralCode = () => {
  const query = getQueryParams();
  const referralCodeFromQueryParams = query?.get('referralCode') || undefined;
  const storedReferralCode = getReferralCodeFromStorage();
  const { address } = useAccount();
  const previousAddress = useRef(address);
  const showReferralModal = Boolean(storedReferralCode);

  const [referralModalOpen, setReferralModalOpen] = useState(false);

  useEffect(() => {
    if (referralCodeFromQueryParams) {
      saveReferralCodeToStorage(referralCodeFromQueryParams);
    }
  }, [referralCodeFromQueryParams]);

  useEffect(() => {
    if (previousAddress.current !== address) {
      previousAddress.current = address;

      setReferralModalOpen(false);
    }

    setReferralModalOpen(showReferralModal);
  }, [address, showReferralModal, storedReferralCode]);

  const handleReferralNotifications = (
    status: string | null | undefined,
    code: string | null,
  ) => {
    if (!code) {
      return;
    }

    if (status === null && code) {
      sendAnalyticsEvent({
        name: 'referrals_link_activated',
        user_id: address,
        variables: { referrals_code: code },
      });

      return toast(
        <ToastNotification
          icon="success"
          message="Your referral link discount is applied"
        />,
      );
    }

    switch (status) {
      case 'USER_ALREADY_REGISTERED':
        toast(
          <ToastNotification
            icon="alert"
            title="Referral discount not applied"
            message="You can apply referral code only during first login with Wormfare."
            type={NotificationType.error}
          />,
          {
            autoClose: false,
          },
        );
        break;
      case 'INVALID_CODE':
        toast(
          <ToastNotification
            icon="alert"
            title="Referral discount not applied"
            message="Provided referral code doesn't exists."
            type={NotificationType.error}
          />,
          {
            autoClose: false,
          },
        );
        break;
    }
  };

  const closeReferralModal = () => {
    setReferralModalOpen(false);
  };

  return {
    referralCode: storedReferralCode,
    referralModalOpen,
    handleReferralNotifications,
    closeReferralModal,
  };
};
