import { useEffect, useState } from 'react';

export const useCountDown = (targetDate: Date) => {
  const countDownDate = targetDate.getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const formatWithLeadingZero = (value: number) => {
  return value < 10 ? `0${value}` : value.toString();
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const formattedDays = formatWithLeadingZero(days);
  const formattedHours = formatWithLeadingZero(hours);
  const formattedMinutes = formatWithLeadingZero(minutes);
  const formattedSeconds = formatWithLeadingZero(seconds);

  const isCompleted = days + hours + minutes + seconds <= 0;

  return {
    days: formattedDays,
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: formattedSeconds,
    isCompleted,
  };
};
