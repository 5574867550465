import cn from 'classnames';

import s from './TabsSwitcher.module.scss';

interface TabOption {
  key: string;
  value: string;
}

export enum TabsSwitcherStyle {
  big = 'big',
  small = 'small',
}

interface TabsSwitcherProps {
  tabOptions: TabOption[];
  activeTab: string;
  style?: TabsSwitcherStyle;
  className?: string;
  onClick: (tab: string) => void;
}

export const TabsSwitcher: React.FC<TabsSwitcherProps> = ({
  tabOptions,
  activeTab,
  style = TabsSwitcherStyle.big,
  className,
  onClick,
}) => {
  return (
    <div className={cn(s.root, s[style], className)}>
      {tabOptions.map((tab) => (
        <button
          key={tab.key}
          className={cn(s.tab, { [s.active]: activeTab.includes(tab.key) })}
          onClick={() => onClick(tab.key)}
        >
          {tab.value}
        </button>
      ))}
    </div>
  );
};
