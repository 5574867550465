import React from 'react';
import cn from 'classnames';

import icons from '../../../public/icons';

import s from './Icon.module.scss';

export type IconName = keyof typeof icons;

interface IconProps extends React.HTMLAttributes<SVGElement> {
  className?: string;
  name: IconName;
  size?: number;
  onClick?: () => void;
}

const Icon = ({ className, name, size = 24, onClick, ...rest }: IconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SVG: React.ComponentClass<any> = icons[name];

  return (
    <SVG
      className={cn(s.root, className)}
      onClick={onClick}
      width={size}
      height={size}
      {...rest}
    />
  );
};

export default Icon;
