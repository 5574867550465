import React, { createContext, useContext, useEffect } from 'react';
import { useAccount, useBalance, useContractRead } from 'wagmi';
import { useAuthContext } from '../AuthContextProvider/AuthContextProvider';
import { User } from '@app/__generated__/graphql';
import { contracts } from '@app/contracts';
import { createPublicClient, formatEther } from 'viem';
import { getGoogleIds, sendAnalyticsEvent, toFixed } from '@app/utils';
import { webSocket } from 'viem';
import { defaultChain } from '../Web3Modal/Web3Modal';
import { appConfig } from '@app/config';
import { saveGoogleIdsToStorage } from '@app/services/investor/utils/credentials';

type UserContextValue = {
  isLoading: boolean;
  user: User | null;
  address: `0x${string}` | undefined;
  balanceWOFR: string | undefined;
  balanceUSDT: string | undefined;
  balanceMATIC: string | undefined;
};

const initialContextValue: UserContextValue = {
  isLoading: false,
  user: null,
  address: undefined,
  balanceWOFR: undefined,
  balanceUSDT: undefined,
  balanceMATIC: undefined,
};

const UserContext = createContext<UserContextValue>(initialContextValue);

type UserContextProviderProps = {
  children: React.ReactNode;
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { address } = useAccount();
  const { user } = useAuthContext();

  const {
    data: balanceUSDT,
    isLoading: usdtBalanceLoading,
    refetch: refetchUsdtBalance,
  } = useBalance({
    address,
    token: contracts.tether.address,
    enabled: Boolean(address),
  });

  const {
    data: balanceWOFR,
    isLoading: wofrBalanceLoading,
    refetch: refetchWofrBalance,
  } = useContractRead({
    ...contracts.tokenSale,
    functionName: 'getTokenBalance',
    account: address,
    enabled: Boolean(address),
  });

  const {
    data: balanceMatic,
    isLoading: maticBalanceLoading,
    refetch: refetchMaticBalance,
  } = useBalance({
    address,
    enabled: Boolean(address),
  });

  useEffect(() => {
    const client = createPublicClient({
      chain: defaultChain,
      transport: webSocket(appConfig.websocketRpcUrl),
    });

    if (address) {
      client?.watchContractEvent({
        ...contracts.tokenSale,
        eventName: 'Buy',
        args: {
          buyer: address,
        },
        onLogs: (logs) => {
          refetchUsdtBalance();
          refetchWofrBalance();
          refetchMaticBalance();
        },
      });

      client?.watchContractEvent({
        ...contracts.tether,
        eventName: 'Transfer',
        args: {
          to: address,
        },
        onLogs: (logs) => {
          refetchUsdtBalance();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const balanceWofrFormatted = toFixed(
    formatEther(balanceWOFR || BigInt(0)),
    2,
  );

  const balanceUsdtFormatted = toFixed(balanceUSDT?.formatted || '0', 0);
  const balanceMaticFormatted = toFixed(balanceMatic?.formatted || '0', 3);

  useEffect(() => {
    (async () => {
      const googleIds = await getGoogleIds();

      saveGoogleIdsToStorage(googleIds.client_id, googleIds.session_id);
    })();
  }, []);

  useEffect(() => {
    if (user?.address) {
      sendAnalyticsEvent({
        name: 'custom_start_session',
        user_id: user.address,
      });
    }
  }, [user?.address]);

  const value = React.useMemo(
    (): UserContextValue => ({
      isLoading:
        usdtBalanceLoading || wofrBalanceLoading || maticBalanceLoading,
      balanceUSDT: balanceUsdtFormatted,
      balanceWOFR: balanceWofrFormatted,
      balanceMATIC: balanceMaticFormatted,
      address,
      user,
    }),
    [
      usdtBalanceLoading,
      wofrBalanceLoading,
      maticBalanceLoading,
      balanceUsdtFormatted,
      balanceWofrFormatted,
      balanceMaticFormatted,
      address,
      user,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }

  return context;
};
