import React from 'react';

import { ButtonMain, ButtonMainStyle, Modal } from '@app/ui-kit';

import s from './ModalSignMessage.module.scss';

export interface ModalSignMessageProps {
  isOpen: boolean;
  isLoading: boolean;
  onClick: () => void;
  onCancel: () => void;
}

const ModalSignMessage = ({
  isOpen,
  isLoading,
  onClick,
  onCancel,
}: ModalSignMessageProps) => {
  const handleSignMessage = () => {
    onClick();
  };

  return (
    <Modal isOpen={isOpen}>
      <div className={s.container}>
        <span className={s.title}>Sign Message</span>
        <div className={s.message}>
          Sign message in your wallet app to prove you own this wallet and
          proceed. Canceling will disconnect you.
        </div>
        <div className={s.buttonsContainer}>
          <ButtonMain
            label="Cancel"
            onClick={onCancel}
            className={s.button}
            style={ButtonMainStyle.white}
          />
          <ButtonMain
            label={'Sign'}
            onClick={handleSignMessage}
            isLoading={isLoading}
            disabled={isLoading}
            className={s.button}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalSignMessage;
