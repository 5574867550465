import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { authLink, errorLink, httpLink, refreshLink } from './apollo-links';

const cache = new InMemoryCache({
  typePolicies: {
    TokenSaleQuery: {
      fields: {
        getReferralsPurchaseHistory: {
          keyArgs: false,

          merge(existing = {}, incoming) {
            return {
              ...incoming,
              items: [...(existing.items || []), ...incoming.items],
            };
          },
        },
      },
    },
  },
});

const investorClient = new ApolloClient({
  link: ApolloLink.from([refreshLink, errorLink, authLink, httpLink]),
  cache,
});

export default investorClient;
