import s from './ErrorDialog.module.scss';

interface ErrorDialogProps {
  errorMessage: string | null;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ errorMessage }) => {
  return <div className={s.root}>{errorMessage}</div>;
};

export default ErrorDialog;
