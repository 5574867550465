'use client';

import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';

import { Icon, InputErrorText } from '@app/ui-kit';
import { useOnClickOutside } from '@app/hooks';

import s from './InputDropdown.module.scss';

interface InputDropdownProps {
  value?: string;
  options: { value: string; label: string }[];
  label?: string;
  placeholder?: string;
  errorText?: string | null | undefined;
  forceErrorState?: boolean;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  onChange: (val: string) => void;
}

const InputDropdown: React.FC<InputDropdownProps> = ({
  value,
  options,
  label,
  placeholder = 'Select...',
  errorText,
  forceErrorState,
  disabled,
  className,
  inputClassName,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const classNames = cn(s.root, className, {
    [s.hasError]: errorText || forceErrorState,
    [s.disabled]: disabled,
    [s.open]: isOpen,
  });

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={classNames} tabIndex={0} ref={ref}>
      {label && <div className={s.label}>{label}</div>}
      <div
        className={cn(s.input, inputClassName)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value && (
          <div className={s.value}>
            {options.find((el) => el.value === value)?.label}
          </div>
        )}
        {!value && <div className={s.placeholder}>{placeholder}</div>}
        <Icon name="arrowDown" className={cn(s.arrow, { [s.up]: isOpen })} />
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.div
            className={s.dropdown}
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: -10 }}
            exit={{ opacity: 0, y: -5 }}
          >
            {options.map((option) => (
              <button
                key={option.value}
                type="button"
                className={cn(s.option, { [s.active]: value === option.value })}
                onClick={() => {
                  onChange(option.value);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </button>
            ))}
          </m.div>
        )}
      </AnimatePresence>
      <InputErrorText errorText={errorText} />
    </div>
  );
};

export default InputDropdown;
