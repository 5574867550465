import cn from 'classnames';

import { Icon, IconName } from '..';

import s from './ExternalLink.module.scss';

export enum ExternalLinkVariant {
  black = 'black',
  green = 'green',
}

interface ExternalLinkProps {
  href: string;
  label?: string;
  icon?: IconName;
  variant?: ExternalLinkVariant;
  useParentFontSize?: boolean;
  className?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  label,
  icon,
  variant = ExternalLinkVariant.green,
  useParentFontSize,
  className,
}) => {
  return (
    <a
      className={cn(
        s.root,
        s[variant],
        { [s.parentFontSize]: useParentFontSize },
        className,
      )}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {label && label}
      {icon && <Icon name={icon} />}
    </a>
  );
};

export default ExternalLink;
