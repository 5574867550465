import cn from 'classnames';
import { ReactNode } from 'react';
import { ToastOptions } from 'react-toastify';

import { Icon, IconName } from '@app/ui-kit';

import s from './ToastNotification.module.scss';

export enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

interface ToastNotificationProps {
  type?: NotificationType;
  title?: string;
  message?: string | ReactNode;
  children?: ReactNode;
  icon?: IconName;
  className?: string;
  toastProps?: ToastOptions;
  closeToast?: () => void;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({
  type = NotificationType.success,
  title,
  message,
  icon,
  children,
  className,
  closeToast,
}) => {
  return (
    <div className={cn(s.root, className, s[type])}>
      <Icon
        name="close"
        onClick={closeToast}
        size={15}
        className={s.closeIcon}
      />
      {icon && <Icon name={icon} />}
      <div className={s.wrapper}>
        <span className={s.title}>{title}</span>
        <span>{message}</span>
        {children}
      </div>
    </div>
  );
};

export default ToastNotification;
