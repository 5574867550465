import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './LoadingIndicator.module.scss';

export enum LoadingIndicatorColor {
  beige = 'beige',
  green = 'green',
  white = 'white',
  grey = 'grey',
}

interface LoadingIndicatorProps {
  valueToObserve: unknown;
  children?: ReactNode;
  width?: string;
  height: string;
  color?: LoadingIndicatorColor;
  animationDurationMs?: number;
  className?: string;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  valueToObserve,
  width,
  height,
  color = LoadingIndicatorColor.beige,
  animationDurationMs = 2000,
  className,
  children,
}) => {
  const isLoading =
    valueToObserve === null ||
    valueToObserve === undefined ||
    valueToObserve === true;

  if (isLoading) {
    return (
      <div
        className={cn(s.root, s[color], className)}
        style={{ width, height, animationDuration: `${animationDurationMs}ms` }}
      />
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return null;
};
