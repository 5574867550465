import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useOnClickOutside } from './useOnClickOutside';
import { DESKTOP_HD } from '@app/constants/mediaQueries';
import { useScrollLock } from '.';
import { useAuthContext } from '@app/context/AuthContextProvider';

export const useHeaderControls = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const isDesktopHd = useMediaQuery({ minWidth: DESKTOP_HD });
  const { isLoading: isAuthLoading } = useAuthContext();
  const toggleHeader = () => setIsOpen(!isOpen);

  useScrollLock(isOpen);

  const closeHeader = () => setIsOpen(false);

  useOnClickOutside(headerRef, () => setIsOpen(false));

  useEffect(() => {
    if (isDesktopHd) {
      closeHeader();
    }
  }, [isDesktopHd]);

  return {
    headerRef,
    isOpen,
    isAuthLoading,
    toggleHeader,
    closeHeader,
  };
};
