import s from './InitialDialog.module.scss';

const InitialDialog: React.FC = () => {
  return (
    <div className={s.root}>
      <p>
        Hi sweetie!
        <br />
        To keep enjoying our services, it&apos;s important that you verify your
        account.
        <br />
        <br />
        Thanks for staying with us!
      </p>
    </div>
  );
};

export default InitialDialog;
