import { useCallback, useEffect, useState } from 'react';

interface PollIntervalProps {
  callback: () => void;
  isActive?: boolean;
  initialSeconds?: number;
  maxSeconds?: number;
}

export const usePollInterval = ({
  callback,
  isActive = false,
  initialSeconds = 4,
  maxSeconds = 160,
}: PollIntervalProps) => {
  const [active, setIsActive] = useState(isActive);
  const [intervalDuration, setIntervalDuration] = useState(initialSeconds);

  const startPolling = useCallback(() => {
    setIsActive(true);
  }, []);

  const stopPolling = useCallback(() => {
    setIsActive(false);
    setIntervalDuration(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (active) {
      timeout = setTimeout(() => {
        callback();

        const newDuration = intervalDuration * 2;

        clearTimeout(timeout);

        if (newDuration > maxSeconds) {
          return stopPolling();
        }

        setIntervalDuration(newDuration);
      }, intervalDuration * 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    callback,
    intervalDuration,
    active,
    maxSeconds,
    initialSeconds,
    startPolling,
    stopPolling,
  ]);

  return { startPolling, stopPolling };
};
