/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import cn from 'classnames';

import { Input, InputErrorText } from '@app/ui-kit';

import s from './CodeInput.module.scss';

interface CodeInputProps {
  value?: string;
  label?: string;
  numInputs?: number;
  shouldAutoFocus?: boolean;
  placeholder?: string;
  errorText?: string | null | undefined;
  className?: string;
  inputClassName?: string;
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onChange: (otp: string) => void;
  onSubmit?: () => void;
}

const CodeInput = ({
  value = '',
  label,
  numInputs = 6,
  shouldAutoFocus = false,
  placeholder,
  errorText,
  className,
  inputClassName,
  onChange,
  onPaste,
  onSubmit,
}: CodeInputProps) => {
  const [activeInput, setActiveInput] = React.useState(0);
  const inputRefs = React.useRef<Array<HTMLInputElement | null>>([]);

  const getValue = () => (value ? value.toString().split('') : []);

  React.useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  React.useEffect(() => {
    if (shouldAutoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [shouldAutoFocus]);

  const getPlaceholderValue = () => {
    if (typeof placeholder === 'string') {
      if (placeholder.length === numInputs) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        console.error(
          'Length of the placeholder should be equal to the number of inputs.',
        );
      }
    }

    return undefined;
  };

  const isInputValueValid = (value: string) => {
    return /^\d$/.test(value);
  };

  const handleChange = (value: string) => {
    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { nativeEvent } = event;

    if (!isInputValueValid(event.target.value)) {
      if (
        //@ts-ignore
        nativeEvent.data === null &&
        //@ts-ignore
        nativeEvent.inputType === 'deleteContentBackward'
      ) {
        event.preventDefault();
        changeCodeAtFocus('');
        focusInput(activeInput - 1);
      }

      event.target.value = '';
    }
  };

  const handleFocus =
    (event: React.FocusEvent<HTMLInputElement>) => (index: number) => {
      setActiveInput(index);
      event.target.select();
    };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const otp = getValue();

    if (event.key === 'Enter' && activeInput === numInputs - 1) {
      onSubmit?.();
    }

    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getValue();

    otp[activeInput] = value[0];
    handleOTPChange(otp);
  };

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join('');

    onChange(otpValue);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const cliboardText = event.clipboardData.getData('text/plain');

    const regex = new RegExp(`^\\d{${numInputs}}$`);

    if (!regex.test(cliboardText)) {
      return;
    }

    const otp = getValue();

    let nextActiveInput = activeInput;

    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);
  };

  return (
    <div className={cn(s.root, className)} onPaste={onPaste}>
      {label && <div className={s.label}>{label}</div>}
      <div className={s.inputsWrapper}>
        {Array.from({ length: numInputs }, (_, index) => index).map((index) => (
          <Input
            key={index}
            value={getValue()[index] ?? ''}
            placeholder={getPlaceholderValue()?.[index] ?? undefined}
            onChange={handleChange}
            ref={(element) => (inputRefs.current[index] = element)}
            onFocus={(e) => handleFocus(e)(index)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            autoComplete="off"
            maxLength={1}
            onInput={handleInputChange}
            forceErrorState={Boolean(errorText)}
            inputClassName={cn(s.input, inputClassName)}
          />
        ))}
      </div>
      <InputErrorText errorText={errorText} />
    </div>
  );
};

export default CodeInput;
