import { parseGwei } from 'viem';

export async function getGasPrice() {
  const apiUrl = `https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle`;

  try {
    const response = await fetch(apiUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch gas prices. Status: ${response.status}`);
    }

    const data = await response.json();
    const fastPrice = data.result.FastGasPrice;

    const aggressiveGasPrice = fastPrice * 2;

    const result = parseGwei(aggressiveGasPrice.toString());

    return result;
  } catch (error) {
    console.error('Error fetching gas prices:', error);

    return null;
  }
}
