import React from 'react';
import cn from 'classnames';

import s from './InputErrorText.module.scss';

interface InputErrorTextProps {
  errorText: string | null | undefined;
  className?: string;
}

const InputErrorText: React.FC<InputErrorTextProps> = ({
  errorText,
  className,
}) => {
  return (
    <div className={cn(s.root, { [s.show]: errorText }, className)}>
      {errorText}
    </div>
  );
};

export default InputErrorText;
