import React from 'react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import cn from 'classnames';

import { ButtonMain, ButtonMainStyle, Icon, Input, Modal } from '@app/ui-kit';
import { useAuthContext } from '@app/context/AuthContextProvider';

import s from './ModalReferralSignUp.module.scss';

export interface ModalReferralSignUpProps {
  referralCode: string | undefined | null;
  isOpen: boolean;
  onClose: (result: false) => void;
}

const ModalReferralSignUp = ({
  referralCode,
  isOpen,
  onClose,
}: ModalReferralSignUpProps) => {
  const { open } = useWeb3Modal();
  const { logout } = useAuthContext();
  const { isConnected } = useAccount();

  if (!referralCode) {
    return null;
  }

  const handleConnect = async () => {
    await logout();
    await open({ view: 'Connect' });
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal isOpen={isOpen} withStyledContainer={false} onClose={handleClose}>
      <div className={s.container}>
        <span className={s.title}>Sign Up with Referral Link</span>
        <div className={s.content}>
          <div className={cn(s.topMessage, { [s.orange]: isConnected })}>
            <Icon
              name={isConnected ? 'alertWarning' : 'success'}
              className={cn(s.iconIndicator, { [s.yellow]: isConnected })}
            />
            <div className={s.messageWrapper}>
              <span>
                {isConnected && 'Referral link can’t be applied'}
                {!isConnected &&
                  'The referral discount will apply as soon as you create your account.'}
              </span>
              {isConnected && (
                <span className={s.info}>
                  To apply referral discount you need to create new account, and
                  then use the same referral link.
                </span>
              )}
            </div>
          </div>
          <Input
            topLabel="Your referral code"
            value={referralCode}
            editable={false}
            onChange={() => {}}
          />
          <p className={s.message}>
            To create an account, you just need to connect your crypto wallet.
          </p>
          {isConnected && (
            <ButtonMain
              label="I understand, Close"
              style={ButtonMainStyle.white}
              onClick={handleClose}
              className={s.button}
            />
          )}
          {!isConnected && (
            <ButtonMain
              label="Connect Wallet"
              onClick={handleConnect}
              className={s.button}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalReferralSignUp;
