import React, { ReactNode } from 'react';
import cn from 'classnames';

import s from './ButtonMain.module.scss';

export enum ButtonMainSize {
  normal = 'normal',
  big = 'big',
}

export enum ButtonMainStyle {
  green = 'green',
  red = 'red',
  white = 'white',
  brown = 'brown',
}

interface ButtonMainProps {
  label?: string;
  children?: ReactNode;
  size?: ButtonMainSize;
  style?: ButtonMainStyle;
  type?: 'button' | 'submit';
  disabled?: boolean;
  className?: string;
  hideLabelMobile?: boolean;
  isLoading?: boolean;
  as?: 'button' | 'a';
  href?: string;
  onClick?: (e?: React.MouseEvent) => void;
}

const ButtonMain = ({
  label,
  size = ButtonMainSize.normal,
  style = ButtonMainStyle.green,
  type = 'button',
  disabled,
  children,
  className,
  isLoading,
  as = 'button',
  href,
  onClick,
}: ButtonMainProps) => {
  const getButtonSideUrl = (
    side: 'left' | 'right',
    size: ButtonMainSize,
    style: ButtonMainStyle,
  ) => {
    if (side === 'left') {
      return `#sprite-button-${size}-left-${style}`;
    }

    if (side === 'right') {
      return `#sprite-button-${size}-right-${style}`;
    }
  };

  const commonProps = {
    className: cn(
      s.root,
      s[size],
      s[style],
      { [s.disabled]: disabled },
      className,
    ),
    onClick,
  };

  const contents = (
    <>
      <span className={s.buttonShadow} />
      <span className={s.backgroundWrapper}>
        <svg className={cn(s.backgroundImage, s.left)}>
          <use href={getButtonSideUrl('left', size, style)} />
        </svg>
        <svg className={cn(s.backgroundImage, s.right)}>
          <use href={getButtonSideUrl('right', size, style)} />
        </svg>
      </span>
      {label && (
        <div className={cn(s.label, { [s.hidden]: isLoading })}>
          <span className={s.text}>{label}</span>
          {isLoading && (
            <span className={cn(s.loader, { [s.visible]: isLoading })}>
              PROCESSING...
            </span>
          )}
        </div>
      )}
      {children}
    </>
  );

  if (as === 'a' && href) {
    return (
      <a {...commonProps} href={href} target="_blank" rel="noreferrer">
        {contents}
      </a>
    );
  }

  return (
    <button {...commonProps} type={type} disabled={disabled}>
      {contents}
    </button>
  );
};

export default ButtonMain;
