import tokenSaleABI from './tokenSaleABI';
import tetherABI from './tetherABI';
import { appConfig } from '@app/config';

export const contracts = {
  tokenSale: {
    address: appConfig.contracts.tokenSale,
    abi: tokenSaleABI,
  },
  tether: {
    address: appConfig.contracts.tether,
    abi: tetherABI,
  },
};
