import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { useMutation } from '@apollo/client';

import { Modal } from '@app/ui-kit';
import { useScrollLock } from '@app/hooks';
import { VerificationSteps } from './types';
import JoinTitle from './JoinTitle';
import VerifyCodeDialog from './VerifyCodeDialog';
import SuccessDialog from './SuccessDialog';
import ErrorDialog from './ErrorDialog';
import ActionButton from './ActionButton';
import {
  GetUserProfileDocument,
  UserSetEmailAddressDocument,
  UserVerifyEmailAddressDocument,
} from '@app/__generated__/graphql';
import InitialDialog from './InitialDialog';
import EmailDialog from './EmailDialog';

import s from './ModalUserVerification.module.scss';
import investorClient from '@app/services/investor';

const EMAIL_REGEXP = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/gi;
const CODE_REGEXP = /^\d{6}$/;

export interface ModalUserVerificationProps {
  isOpen: boolean;
  onClick: () => void;
  variant: 'UserVerification' | 'EmailChange';
}

const ModalUserVerification = ({
  isOpen,
  onClick,
  variant = 'UserVerification',
}: ModalUserVerificationProps) => {
  useScrollLock(isOpen);

  const [step, setStep] = useState<VerificationSteps>(
    variant !== 'EmailChange'
      ? VerificationSteps.initial
      : VerificationSteps.sendCode,
  );
  const [fields, setFields] = useState({
    email: '',
    code: '',
  });

  const [inputError, setInputError] = useState('');
  const [networkError, setNetworkError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [sendEmailAddress, { loading: sendEmailAddressLoading }] = useMutation(
    UserSetEmailAddressDocument,
  );

  const [verifyEmailAddress, { loading: verifyEmailAddressLoading }] =
    useMutation(UserVerifyEmailAddressDocument);

  const resetError = () => {
    setInputError('');
    setNetworkError('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setErrorFromApi = (e: any) => {
    const error = e?.message || 'Oops! Something went wrong';

    setNetworkError(error);
  };

  const handleSendCode = async (type: 'normal' | 'resend') => {
    resetError();

    if (type === 'normal') {
      const isCorrect = EMAIL_REGEXP.test(fields.email);

      if (!isCorrect) {
        return setInputError('Invalid email address');
      }
    }

    if (!fields.email) {
      return;
    }

    try {
      await sendEmailAddress({ variables: { email: fields.email } });
      setCodeSent(true);
      setStep(VerificationSteps.verifyCode);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);

      setErrorFromApi(e);
    }
  };

  const handleVerifyCode = async () => {
    resetError();

    const isCorrect = CODE_REGEXP.test(fields.code);

    if (!isCorrect) {
      return setInputError('Invalid code');
    }

    try {
      await verifyEmailAddress({ variables: { code: fields.code } });
      setStep(VerificationSteps.success);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);

      setErrorFromApi(e);
    }
  };

  const handleChangeStep = async () => {
    resetError();

    if (step === VerificationSteps.initial) {
      setStep(VerificationSteps.sendCode);
    }

    if (step === VerificationSteps.sendCode) {
      await handleSendCode('normal');
    }

    if (step === VerificationSteps.verifyCode) {
      await handleVerifyCode();
    }

    if (step === VerificationSteps.success) {
      onClick();
      setStep(VerificationSteps.initial);
      await investorClient.refetchQueries({
        include: [GetUserProfileDocument],
      });
    }

    if (step === VerificationSteps.error) {
      setStep(VerificationSteps.sendCode);
    }
  };

  const handleChangeField = useCallback(
    (val: string, field: keyof typeof fields) => {
      setFields((prev) => ({ ...prev, [field]: val }));

      resetError();
    },
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      containerClassName={s.container}
      withCloseIcon={true}
      onClose={onClick}
    >
      <AnimatePresence initial={false} mode="wait">
        <m.form
          key={step}
          initial={{ opacity: 0, y: -150 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 150 }}
          transition={{ type: 'tween', duration: 0.4 }}
          onSubmit={handleVerifyCode}
          className={s.form}
        >
          <JoinTitle variant={variant} currentStep={step} />
          {step === VerificationSteps.initial && <InitialDialog />}
          {step === VerificationSteps.sendCode && (
            <EmailDialog
              value={fields.email}
              errorText={inputError}
              onChange={(val) => handleChangeField(val.trim(), 'email')}
              onEnterPress={handleChangeStep}
            />
          )}
          {step === VerificationSteps.verifyCode && (
            <VerifyCodeDialog
              code={fields.code}
              email={fields.email}
              errorText={inputError}
              onChange={(val) => handleChangeField(val, 'code')}
              onResendCodeClick={async () => await handleSendCode('resend')}
              resendDisabled={codeSent}
              onCountdownComplete={() => setCodeSent(false)}
              onEnterPress={handleChangeStep}
              onBack={() => setStep(VerificationSteps.sendCode)}
            />
          )}
          {step === VerificationSteps.success && <SuccessDialog />}
          {step === VerificationSteps.error && (
            <ErrorDialog errorMessage={networkError} />
          )}
          <ActionButton
            variant={variant}
            currentStep={step}
            isLoading={sendEmailAddressLoading || verifyEmailAddressLoading}
            onClick={handleChangeStep}
            className={s.actionButton}
          />
        </m.form>
      </AnimatePresence>
      <div
        className={cn(s.networkError, {
          [s.displayNone]: step === VerificationSteps.error || !networkError,
        })}
      >
        {networkError}
      </div>
    </Modal>
  );
};

export default ModalUserVerification;
