import { User } from '@app/__generated__/graphql';
import { useEffect, useState } from 'react';

interface VerifyUserAccountProps {
  isAuth: boolean;
  currentUser: User | null;
}

export const useVerifyUserAccount = ({
  isAuth,
  currentUser,
}: VerifyUserAccountProps) => {
  const isVerified = currentUser?.isVerified;
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);

  useEffect(() => {
    if (isAuth && isVerified) {
      setVerifyModalOpen(false);
    }
  }, [isAuth, isVerified]);

  const openVerifyModal = () => {
    if (!isVerified) {
      setVerifyModalOpen(true);
    }
  };

  const closeVerifyModal = () => {
    setVerifyModalOpen(false);
  };

  return { isVerified, verifyModalOpen, openVerifyModal, closeVerifyModal };
};
