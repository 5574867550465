import cn from 'classnames';

import { VerificationSteps } from '../types';

import s from './JoinTitle.module.scss';

interface JoinTitleProps {
  currentStep: VerificationSteps;
  className?: string;
  variant: 'UserVerification' | 'EmailChange';
}

const JoinTitle: React.FC<JoinTitleProps> = ({
  currentStep,
  className,
  variant = 'UserVerification',
}) => {
  let text = '';

  switch (currentStep) {
    case VerificationSteps.initial:
      text = 'Account verification';
      break;
    case VerificationSteps.sendCode:
      {
        variant === 'UserVerification'
          ? (text = 'Provide Your Email')
          : (text = 'Enter your New Email');
      }
      break;
    case VerificationSteps.verifyCode:
      text = 'Verify Your Email';
      break;
    case VerificationSteps.success:
      text = 'Success';
      break;
    case VerificationSteps.error:
      text = 'Oops! Error!';
      break;
    default:
      text = '';
  }

  if (!text) {
    return null;
  }

  return <h3 className={cn(s.root, className)}>{text}</h3>;
};

export default JoinTitle;
