import React, { ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';

import Link from 'next/link';
import { Icon, IconName } from '@app/ui-kit';

import s from './ButtonSecondary.module.scss';

interface ButtonMainProps {
  label: string;
  iconName: IconName;
  type?: 'button' | 'submit';
  disabled?: boolean;
  as?: 'button' | 'a' | 'link';
  href?: string;
  className?: string;
  onClick?: () => void;
}

const ButtonSecondary = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonMainProps
>(
  (
    {
      label,
      type = 'button',
      disabled,
      className,
      as = 'button',
      href,
      iconName,
      onClick,
    },
    ref,
  ) => {
    const commonProps = {
      className: cn(s.root, { [s.disabled]: disabled }, className),
      onClick,
    };
    const contents = (
      <>
        <Icon name={iconName} className={s.icon} />
        <span className={s.text}>{label}</span>
      </>
    );

    if (as === 'a' && href) {
      return (
        <a
          {...commonProps}
          href={href}
          target="_blank"
          rel="noreferrer"
          ref={ref as ForwardedRef<HTMLAnchorElement>}
        >
          {contents}
        </a>
      );
    }

    if (as === 'link' && href) {
      return (
        <Link
          {...commonProps}
          href={href}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
        >
          {contents}
        </Link>
      );
    }

    return (
      <button
        {...commonProps}
        type={type}
        disabled={disabled}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        {contents}
      </button>
    );
  },
);

ButtonSecondary.displayName = 'ButtonSecondary';

export default ButtonSecondary;
