import { ButtonMain } from '@app/ui-kit';
import { VerificationSteps } from '../types';

interface ActionButtonProps {
  currentStep: VerificationSteps;
  isLoading: boolean;
  className?: string;
  onClick: () => void;
  variant: 'UserVerification' | 'EmailChange';
}

const ActionButton: React.FC<ActionButtonProps> = ({
  currentStep,
  isLoading,
  className,
  variant = 'UserVerification',
  onClick,
}) => {
  let text = '';

  if (isLoading) {
    text = 'Loading...';
  }

  if (!isLoading) {
    switch (currentStep) {
      case VerificationSteps.initial:
        text = "Okay, let's go";
        break;
      case VerificationSteps.sendCode:
        {
          variant === 'UserVerification'
            ? (text = 'Confirm Email')
            : (text = 'Confirm New Email');
        }
        break;
      case VerificationSteps.verifyCode:
        text = 'Confirm';
        break;
      case VerificationSteps.success:
        text = 'Close';
        break;
      case VerificationSteps.error:
        text = 'Try Again';
        break;
      default:
        text = '';
    }
  }

  if (!text) {
    return null;
  }

  return (
    <ButtonMain
      className={className}
      label={text}
      disabled={isLoading}
      type={currentStep === VerificationSteps.verifyCode ? 'submit' : 'button'}
      onClick={(e) => {
        e?.preventDefault();
        onClick();
      }}
    />
  );
};

export default ActionButton;
