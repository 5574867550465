/* eslint-disable */
// This file was generated by script
// this file is used to generate icon names from files within this directory
// icons are used in Icon component

import alertWarning from './alert-warning.svg';
import alert from './alert.svg';
import arrowDown from './arrow-down.svg';
import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';
import arrowUp from './arrow-up.svg';
import calculator from './calculator.svg';
import chat from './chat.svg';
import checkMark from './check-mark.svg';
import chevronDown from './chevron-down.svg';
import click from './click.svg';
import closeCircle from './close-circle.svg';
import close from './close.svg';
import code from './code.svg';
import copy from './copy.svg';
import discord from './discord.svg';
import document from './document.svg';
import externalLink from './external-link.svg';
import helpCircleOutline from './help-circle-outline.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import matic from './matic.svg';
import percentCircle from './percent-circle.svg';
import progressCircleDashed from './progress-circle-dashed.svg';
import progressCircleSolid from './progress-circle-solid.svg';
import soundActive from './sound-active.svg';
import soundMuted from './sound-muted.svg';
import success from './success.svg';
import telegram from './telegram.svg';
import twitter from './twitter.svg';
import usdt from './usdt.svg';
import video from './video.svg';
import website from './website.svg';
import wofr from './wofr.svg';
import disconnect from './disconnect.svg';
import disconnectSmall from './disconnect-small.svg';
import change from './change.svg';
import settings from './settings.svg';
import { from } from '@apollo/client';

const icons = {
  alertWarning,
  alert,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  calculator,
  chat,
  checkMark,
  chevronDown,
  click,
  closeCircle,
  close,
  code,
  copy,
  discord,
  document,
  externalLink,
  helpCircleOutline,
  instagram,
  linkedin,
  matic,
  percentCircle,
  progressCircleDashed,
  progressCircleSolid,
  soundActive,
  soundMuted,
  success,
  telegram,
  twitter,
  usdt,
  video,
  website,
  wofr,
  disconnect,
  disconnectSmall,
  change,
  settings,
};

export default icons;
