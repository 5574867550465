'use client';

import {
  EIP6963Connector,
  createWeb3Modal,
  useWeb3ModalTheme,
} from '@web3modal/wagmi/react';
import { ReactNode, useEffect } from 'react';
import { WagmiConfig, configureChains, createConfig, Chain } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { polygon } from 'viem/chains';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { alchemyProvider } from 'wagmi/providers/alchemy';

import { isClient } from '@app/utils';
import { appConfig } from '@app/config';
import { SUPPORTED_WALLETS_IDS } from './supportedWallets';

const projectId = appConfig.walletConnectProjectId;

export const devChain = {
  id: 1337,
  name: 'Wormfare Dev',
  network: 'matic',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: {
      http: ['https://eth-network.wormfare.com'],
      webSocket: ['wss://eth-network.wormfare.com'],
    },
    default: {
      http: ['https://eth-network.wormfare.com'],
      webSocket: ['wss://eth-network.wormfare.com'],
    },
  },
  testnet: true,
} as const satisfies Chain;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, devChain],
  [
    alchemyProvider({
      apiKey: appConfig.alchemyApiKey,
    }),
    publicProvider(),
  ],
);
export const walletConnectLogo = isClient()
  ? `${window?.location}/media/wormfare-logo.svg`
  : 'https://wormfare.com/media/wormfare-logo.svg';

const metadata = {
  name: 'Wormfare Dashboard',
  description: 'Wormfare Dashboard',
  url: 'https://wormfare.com/',
  icons: [walletConnectLogo],
};

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: false, metadata },
    }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: metadata.name },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

export const defaultChain = chains.find(
  (el) => +el.id === +appConfig.requiredChainId,
);

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  defaultChain,
  includeWalletIds: SUPPORTED_WALLETS_IDS,
});

export function Web3Modal({ children }: { children: ReactNode }) {
  const { setThemeMode, setThemeVariables } = useWeb3ModalTheme();

  useEffect(() => {
    setThemeMode('dark');

    setThemeVariables({
      '--w3m-z-index': 10000,
      '--w3m-accent': 'var(--vivid-green)',
      '--w3m-color-mix': 'var(--brown)',
      '--w3m-color-mix-strength': 40,
      '--w3m-font-family': 'var(--font-troika)',
      '--w3m-border-radius-master': '16px',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>;
}
