import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

import { GetUserProfileDocument, User } from '@app/__generated__/graphql';
import {
  clearCredentials,
  loadCredentialsFormStorage,
  loadCurrentUserFromStorage,
  saveCurrentUserToStorage,
} from '@app/services/investor/utils/credentials';
import { toast } from 'react-toastify';
import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import investorClient from '@app/services/investor/investorClient';

export const useUser = () => {
  const storageUser = loadCurrentUserFromStorage();
  const [currentUser, setCurrentUser] = useState<User | null>(storageUser);
  const [isLoading, setIsLoading] = useState(true);

  const [
    getUser,
    { loading: fetchUserLoading, error: userError, data: fetchedUserData },
  ] = useLazyQuery(GetUserProfileDocument, { fetchPolicy: 'no-cache' });

  const fetchCurrentUser = useCallback(async () => {
    try {
      const { data } = await getUser();
      const user = data?.user?.getProfile;

      if (user) {
        setCurrentUser(user);
        saveCurrentUserToStorage(user);
      }
    } catch (err) {
      console.error(err);
      clearCredentials();
      setCurrentUser(null);
    }

    setIsLoading(false);
  }, [getUser]);

  useEffect(() => {
    if (loadCredentialsFormStorage()) {
      fetchCurrentUser();
    } else {
      setIsLoading(false);
    }
  }, [fetchCurrentUser]);

  const resetUser = () => {
    setCurrentUser(null);
    clearCredentials();
    investorClient.clearStore();
  };

  useEffect(() => {
    const user = fetchedUserData?.user?.getProfile;

    Sentry.setContext('dashboard_user', user || { notLoggedIn: true });

    if (user) {
      setCurrentUser(user);
      saveCurrentUserToStorage(user);
    }
  }, [fetchedUserData]);

  useEffect(() => {
    if (userError) {
      toast(
        <ToastNotification
          icon="alert"
          title="Error"
          message="Can't get user profile"
          type={NotificationType.error}
        />,
        {
          autoClose: false,
        },
      );
    }
  }, [userError]);

  return {
    currentUser,
    userLoading: isLoading || fetchUserLoading,
    userError,
    fetchedUserData,
    fetchCurrentUser,
    resetUser,
  };
};
