import { useEffect, useState } from 'react';

import { CodeInput } from '@app/components/CodeInput';

import s from './VerifyCodeDialog.module.scss';
import { ButtonMain } from '@app/ui-kit';

interface VerifyCodeDialogProps {
  code: string;
  email: string;
  errorText: string | null;
  resendDisabled: boolean;
  onChange: (value: string) => void;
  onResendCodeClick: () => void;
  onCountdownComplete: () => void;
  onEnterPress: () => void;
  onBack: () => void;
}

const RESEND_CODE_TIMEOUT_S = 60;

const VerifyCodeDialog: React.FC<VerifyCodeDialogProps> = ({
  code,
  email,
  errorText,
  resendDisabled,
  onChange,
  onResendCodeClick,
  onCountdownComplete,
  onEnterPress,
  onBack,
}) => {
  const [resendCountdown, setResendCountdown] = useState(RESEND_CODE_TIMEOUT_S);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (resendDisabled && resendCountdown > 0) {
      timer = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (resendCountdown < 1) {
      onCountdownComplete();
    }

    return () => clearInterval(timer);
  }, [resendDisabled, resendCountdown, onCountdownComplete]);

  return (
    <>
      <CodeInput
        value={code}
        label="Verification code:"
        onChange={onChange}
        numInputs={6}
        errorText={errorText}
        onSubmit={onEnterPress}
      />
      <div className={s.description}>
        <p>
          We&apos;ve sent a 6-digit verification code to:
          <br /> {email}
        </p>
        <br />
        <p>
          It can take up to one minute to receive the code. If you haven&apos;t
          received it, please try requesting a{' '}
          <button
            className={s.resendCode}
            type="button"
            disabled={resendDisabled}
            onClick={() => {
              onResendCodeClick();
              setResendCountdown(RESEND_CODE_TIMEOUT_S);
            }}
          >
            resend
          </button>
          .
        </p>
        {resendDisabled && ` Resend available in ${resendCountdown} seconds`}
      </div>
      <ButtonMain label="Go Back" onClick={onBack} />
    </>
  );
};

export default VerifyCodeDialog;
