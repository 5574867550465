import SocialsRow from '@app/components/SocialsRow';

import s from './SuccessDialog.module.scss';

const SuccessDialog: React.FC = () => {
  return (
    <div className={s.root}>
      Thank you!
      <br />
      Your account has been verified.
    </div>
  );
};

export default SuccessDialog;
