import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AuthLoginDocument,
  AuthMessageDocument,
} from '@app/__generated__/graphql';
import investorClient from '@app/services/investor';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { useReferralCode, useUser } from '.';
import {
  clearCredentials,
  clearReferralCodeFromStorage,
  saveCredentialsToStorage,
} from '@app/services/investor/utils/credentials';
import { toast } from 'react-toastify';
import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import { getConnectedWalletName, sendAnalyticsEvent } from '@app/utils';
import ModalSignMessage from '@app/components/ModalSignMessage';

export const useAuth = () => {
  const { address, isConnected, isDisconnected, connector } = useAccount();
  const previousAddress = useRef(address);
  const { currentUser, userLoading, fetchCurrentUser, resetUser } = useUser();
  const { referralCode, handleReferralNotifications } = useReferralCode();
  const { disconnect } = useDisconnect();
  const [nonce, setNonce] = useState<string | undefined>();
  const [getNonce, { loading: nonceLoading, error: nonceError }] = useLazyQuery(
    AuthMessageDocument,
    { fetchPolicy: 'no-cache' },
  );
  const [login, { loading: loginLoading, error: loginError }] = useMutation(
    AuthLoginDocument,
    { fetchPolicy: 'no-cache' },
  );
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowSignMessage, setShouldShowSignMessage] = useState(false);
  const {
    error: signError,
    data: signMessageData,
    signMessageAsync,
    reset: resetSignature,
    isLoading: signMessageLoading,
  } = useSignMessage();

  const handleGetMessage = useCallback(async () => {
    if (!address || !isConnected) {
      return;
    }

    setIsLoading(true);

    const res = await getNonce({
      variables: { address: address as string },
    });

    setNonce(res.data?.auth.getMessage);
    setShouldShowSignMessage(true);
  }, [address, getNonce, isConnected]);

  const generateSignature = async () => {
    try {
      await signMessageAsync({
        message: nonce as string,
      });
    } catch (e) {
      disconnect();
      console.error(e);
    }

    setShouldShowSignMessage(false);
  };

  const logout = useCallback(async () => {
    setNonce(undefined);
    clearCredentials();
    resetUser();
    resetSignature();
    disconnect();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelSignMessage = () => {
    setShouldShowSignMessage(false);
    logout();
  };

  const sendGaWalletConnectedEvent = async (isNewUser: boolean | undefined) => {
    let eventName = 'repeat_connect_wallet';
    const walletName = await getConnectedWalletName(connector);

    if (isNewUser) {
      eventName = 'first_connect_wallet';
    }

    sendAnalyticsEvent({
      name: eventName,
      user_id: address,
      variables: {
        wallet_name: walletName,
      },
    });
  };

  useEffect(() => {
    const handleLogin = async () => {
      if (!signMessageData) {
        return;
      }

      try {
        const { data: credentialsData } = await login({
          variables: {
            address: address as string,
            signature: signMessageData,
            referralCode,
          },
        });

        await sendGaWalletConnectedEvent(credentialsData?.auth.login.isNewUser);

        handleReferralNotifications(
          credentialsData?.auth.login.referralCodeError,
          referralCode,
        );

        if (credentialsData?.auth) {
          saveCredentialsToStorage(
            credentialsData?.auth?.login.accessToken,
            credentialsData?.auth?.login.refreshToken,
          );

          await fetchCurrentUser();
          clearReferralCodeFromStorage();
        }

        await investorClient.resetStore();
      } catch (err) {
        console.error(err);
        disconnect();
        resetUser();
        clearCredentials();
        toast(
          <ToastNotification
            icon="alert"
            title="Error"
            message="Refresh page and try again"
            type={NotificationType.error}
          />,
          {
            autoClose: false,
          },
        );
      }

      setIsLoading(false);
    };

    if (signMessageData) {
      handleLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signMessageData]);

  useEffect(() => {
    const handleAddressChanged = async () => {
      if (previousAddress.current !== address) {
        previousAddress.current = address;
        setNonce(undefined);
        await resetSignature();

        if (address) {
          await handleGetMessage();
        }
      }
    };

    handleAddressChanged();
  }, [address, handleGetMessage, resetSignature]);

  useEffect(() => {
    if (signError || isDisconnected || loginError) {
      logout();
    }
  }, [logout, signError, isDisconnected, loginError]);

  useEffect(() => {
    if (nonceError) {
      toast(
        <ToastNotification
          icon="alert"
          title="Error"
          message="Can't obtain message to sign"
          type={NotificationType.error}
        />,
        {
          autoClose: false,
        },
      );
    }
  }, [nonceError]);

  const authLoading =
    userLoading ||
    loginLoading ||
    nonceLoading ||
    signMessageLoading ||
    isLoading;

  const modalSignMessage = (
    <ModalSignMessage
      isLoading={signMessageLoading}
      onCancel={handleCancelSignMessage}
      onClick={generateSignature}
      isOpen={shouldShowSignMessage}
    />
  );

  return {
    currentUser,
    userLoading,
    loginLoading,
    nonceLoading,
    signMessageLoading,
    authLoading,
    shouldShowSignMessage,
    modalSignMessage,
    generateSignature,
    logout,
  };
};
