import { useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  hasMore: boolean;
  loadMore: () => void;
}

export const useInfiniteScroll = ({
  hasMore,
  loadMore,
}: InfiniteScrollProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anchor = anchorRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting) {
            await loadMore();
          }
        });
      },
      { threshold: 0.5 },
    );

    if (anchor) {
      observer.observe(anchor);
    }

    return () => {
      if (anchor) {
        observer.unobserve(anchor);
      }
    };
  }, [anchorRef, hasMore, loadMore]);

  const viewAnchor = hasMore ? (
    <div
      ref={anchorRef}
      style={{
        padding: '20px 10px',
        width: '100%',
        pointerEvents: 'none',
        position: 'relative',
        flexShrink: 0,
        textAlign: 'center',
      }}
    >
      Loading...
    </div>
  ) : null;

  return { viewAnchor };
};
