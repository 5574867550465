import { FRAMER_OPACITY_TRANSITION } from '@app/constants/framerTransitions';
import { Icon } from '@app/ui-kit';
import { debounce } from '@app/utils';
import { AnimatePresence, m } from 'framer-motion';
import React, { useState, useEffect } from 'react';

const buttonAppearOffset = 500;
const scrollCheckInterval = 500;

export const useScrollTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > buttonAppearOffset);
    };

    const debouncedHandleScroll = debounce(handleScroll, scrollCheckInterval);

    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setShowButton(false);
  };

  return (
    <>
      <AnimatePresence>
        {showButton && (
          <m.button
            {...FRAMER_OPACITY_TRANSITION}
            onClick={scrollToTop}
            style={{
              position: 'fixed',
              width: '60px',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              right: '25px',
              bottom: '90px',
              backgroundColor: 'var(--mid-brown)',
              zIndex: 3,
              borderRadius: '50%',
              cursor: 'pointer',
            }}
          >
            <Icon name="arrowUp" color="white" size={24} />
          </m.button>
        )}
      </AnimatePresence>
    </>
  );
};

export default useScrollTop;
