import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  GetTelegramAuthLinkDocument,
  GetTelegramIdDocument,
  GetUserProfileDocument,
  UserDisconnectTelegramDocument,
} from '@app/__generated__/graphql';
import ToastNotification, {
  NotificationType,
} from '@app/components/ToastNotification';
import { useAuthContext } from '@app/context/AuthContextProvider';
import investorClient from '@app/services/investor';

const TELEGRAM_ID_REFETCH_INTERVAL = 4000;
const MAX_RETRIES_AMOUNT = 20;

export const useTelegramAuth = () => {
  const { isTelegramConnected } = useAuthContext();
  const [getAuthUrl, { data: authUrl, loading: isLoading }] = useLazyQuery(
    GetTelegramAuthLinkDocument,
  );
  const [isInit, setIsInit] = useState(false);
  const [authCheckInProgress, setAuthCheckInProgress] = useState(false);
  const [_, { data: telegramId, refetch: refetchTelegramId }] = useLazyQuery(
    GetTelegramIdDocument,
  );
  const [isAuth, setIsAuth] = useState(isTelegramConnected);
  const [authCode, setAuthCode] = useState<string | undefined>();
  const intervalRef = useRef<NodeJS.Timeout>();
  const [_disconnectTelegram] = useMutation(UserDisconnectTelegramDocument);

  console.log('[info]: ', isTelegramConnected, isAuth);

  const disconnectTelegram = async () => {
    await _disconnectTelegram();
    await investorClient.refetchQueries({
      include: [GetUserProfileDocument],
    });
    setIsAuth(false);
  };

  const retrieveAuthCode = useCallback(() => {
    if (!authUrl) {
      return;
    }

    const url = new URL(authUrl.authExternal.getTelegramAuthLink);
    const authCodeFromUrl = url.searchParams.get('start');

    if (authCodeFromUrl) {
      setAuthCode(authCodeFromUrl);
    }
  }, [authUrl]);

  useEffect(() => {
    const handleGetUrl = async () => {
      if (!isTelegramConnected) {
        try {
          await getAuthUrl();
          retrieveAuthCode();
        } catch (e) {
          toast(
            <ToastNotification
              type={NotificationType.error}
              title="Error"
              message="Can't get auth URL! Please refresh the page."
            />,
          );
          console.error(e);
        }
      }

      setIsInit(true);
    };

    handleGetUrl();
  }, [getAuthUrl, isTelegramConnected, retrieveAuthCode]);

  const handleCheckIsAuth = useCallback(async () => {
    let retriesAmount = 0;

    intervalRef.current = setInterval(async () => {
      try {
        await refetchTelegramId();
        retriesAmount++;

        if (retriesAmount > MAX_RETRIES_AMOUNT) {
          clearInterval(intervalRef.current);
          setAuthCheckInProgress(false);

          toast(
            <ToastNotification
              type={NotificationType.warning}
              message="Please go to telegram app Slap bot, approve connection of your Telegram account to your Dashboard account and refresh the page"
            />,
            { autoClose: false },
          );

          return;
        }

        if (telegramId?.user.getProfile.telegramId) {
          clearInterval(intervalRef.current);
          await investorClient.refetchQueries({
            include: [GetUserProfileDocument],
          });
          toast(
            <ToastNotification
              type={NotificationType.success}
              title="Success"
              message="You have successfully linked your telegram account!"
            />,
          );
          setAuthCheckInProgress(false);
          setIsAuth(true);
        }
      } catch (error) {
        console.error(error);
      }
    }, TELEGRAM_ID_REFETCH_INTERVAL);
  }, [refetchTelegramId, telegramId?.user.getProfile.telegramId]);

  useEffect(() => {
    if (authCheckInProgress) {
      handleCheckIsAuth();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [authCheckInProgress, handleCheckIsAuth]);

  return {
    authUrl,
    authCode,
    isLoading: isLoading || authCheckInProgress || !isInit,
    isAuth,
    setAuthCheckInProgress,
    disconnectTelegram,
  };
};
