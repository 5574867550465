'use client';

import React from 'react';
import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';

import { Icon } from '@app/ui-kit';

import s from './Checkbox.module.scss';

interface LoadingIndicatorProps {
  isChecked?: boolean;
  className?: string;
  onClick: (isChecked: boolean) => void;
}

const transition = {
  initial: { opacity: 0, scale: 2, y: 0 },
  animate: { opacity: 1, scale: 1, y: 0 },
  exit: { opacity: 0, scale: 2, y: 0 },
};

export const Checkbox: React.FC<LoadingIndicatorProps> = ({
  isChecked,
  className,
  onClick,
}) => {
  const handleCheck = () => {
    onClick(!isChecked);
  };

  return (
    <button
      className={cn(s.root, { [s.checked]: isChecked }, className)}
      aria-label="checkbox"
      type="button"
      onClick={handleCheck}
    >
      <AnimatePresence>
        {isChecked && (
          <m.div {...transition}>
            <Icon name="checkMark" />
          </m.div>
        )}
      </AnimatePresence>
    </button>
  );
};
